<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <defs>
    <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop offset="0%" style="stop-color:#4a4a4a;stop-opacity:1" />
      <stop offset="100%" style="stop-color:#2a2a2a;stop-opacity:1" />
    </linearGradient>
  </defs>
  
  <circle cx="50" cy="50" r="48" fill="url(#grad)"/>
  
  <path d="M50 20 L30 80 L70 80 Z" fill="none" stroke="white" stroke-width="4"/>
  <line x1="37" y1="60" x2="63" y2="60" stroke="white" stroke-width="4"/>
  
  <circle cx="50" cy="50" r="46" fill="none" stroke="white" stroke-width="1.5"/>
</svg>
</template>

<script>
export default {
  name: 'ProjectLogo'
}
</script>
